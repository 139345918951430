
export const plantTypeHabitList = [
    "Tree",
    "Herb",
    "Shrub",
    "Climber",
]

export const plantTypeCategories = [
    "Native",
    "Non Native",
    "Cultivated",
]